import { createSlice } from '@reduxjs/toolkit';
import { getTeam } from './teamThunk';

interface ITeamState {
  listTeam: Array<any> | null | undefined;
}

const initialState: ITeamState = {
  listTeam: [],
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTeam.fulfilled, (state, action) => {
      state.listTeam = action.payload.data.teams;
    });
  },
});

export const teamActions = teamSlice.actions;

export const selectListAllTeam = (state: any) => state.team.listTeam;

const teamReducer = teamSlice.reducer;
export default teamReducer;
