import classNames from 'classnames/bind';

import { Button, Collapse, Empty, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { IQueryGetRounds } from '~/apis/round';
import DropdownGroup from '~/components/dropdownGroup/dropdownGroup';
import ModalImport from '~/components/import/ModalImport';
import { LoadingContext } from '~/contexts/LoadingContext';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { selectListRound } from '~/thunks/round/roundSlice';
import { getRound } from '~/thunks/round/roundThunk';
import { selectListTournament } from '~/thunks/tournament/tournamentSlice';
import { getTournament } from '~/thunks/tournament/tournamentThunk';
import { convertDataSourceLeague, convertUpdated, objectIsEmpty } from '~/utils/helper';
import i18n from '~/utils/i18n';
import styles from './Round.module.scss';
import TableMatch from './tableMatch/TableMatch';
import { StorageConstants } from '~/utils/enum';

const cx = classNames.bind(styles);

interface Tournament {
  label: string;
  value: string;
}

interface TournamentResponse {
  id: string;
  name: string;
  year: string;
}

interface Round {
  label: JSX.Element;
  children: JSX.Element;
}

export interface IDataSelect {
  value: string | number;
  label: string;
}

export interface IParamGetRound {
  roundId?: string | number;
  tournamentId?: string | number;
  group?: string | number;
}

const RoundList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const dataSourceLeagues = useAppSelector(selectListTournament);
  const dataSourceRounds = useAppSelector(selectListRound);

  const [showModalImport, setShowModalImport] = useState(false);
  const [leagueSelected, setLeagueSelected] = useState<string>(
    !objectIsEmpty(params) ? params?.tournamentId : ''
  );
  const [groupSelected, setGroupSelected] = useState<string>(
    !objectIsEmpty(params) ? params?.group : ''
  );
  const [dataLeagues, setDataLeagues] = useState<Tournament[]>([]);
  const [dataRounds, setDataRounds] = useState<Round[]>([]);
  const [stateLanguage, setStateLanguage] = useState<string>('');

  //#region useEffect
  useEffect(() => {
    callApiGetTournament();
  }, []);

  useEffect(() => {
    if (!leagueSelected && !groupSelected) {
      return;
    }

    if (leagueSelected) {
      localStorage.setItem(StorageConstants.LEAGUE_SELECTED, leagueSelected);
    }

    callApiGetRound({ tournamentId: +leagueSelected, groupName: groupSelected });
    setSearchParams({ ...params, tournamentId: leagueSelected, group: groupSelected });
  }, [groupSelected, leagueSelected]);

  useEffect(() => {
    if (dataSourceLeagues && dataSourceLeagues?.length > 0) {
      const dataSource = convertDataSourceLeague(dataSourceLeagues);
      setDataLeagues(dataSource);
    }
  }, [dataSourceLeagues]);

  useEffect(() => {
    if (dataSourceRounds.length > 0) {
      const dataRounds = dataSourceRounds.map((data: any) => {
        return {
          label: (
            <LabelRound
              nameOfRound={`${t('admin_group_day')} ${data?.day}`}
              dateOfRound={convertUpdated(data?.createdAt)}
            />
          ),
          children: <TableMatch matches={data?.matches} />,
        };
      });
      setDataRounds(dataRounds);
      return;
    }
    setDataRounds([]);
  }, [dataSourceRounds, stateLanguage]);

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      setStateLanguage(lng);
    });
  }, []);
  //#endregion useEffect

  //#region Handle Function
  const LabelRound = ({
    nameOfRound,
    dateOfRound,
  }: {
    nameOfRound: string;
    dateOfRound: string;
  }) => {
    return (
      <div className={cx('round_collapse_header')}>
        <div className={cx('name_of_round')}>{nameOfRound}</div>
        <div>{dateOfRound}</div>
      </div>
    );
  };

  const callApiGetRound = (payload: IQueryGetRounds) => {
    dispatch(getRound(payload))
      .unwrap()
      .then((response) => {
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  const callApiGetTournament = (payload?: {
    groupSelected: string;
    tournamentImported: string;
  }) => {
    loadingContext?.show();
    dispatch(getTournament())
      .unwrap()
      .then((res) => {
        const { tournament } = res.data;
        if (!tournament || tournament.length <= 0) {
          loadingContext?.hide();
          return;
        }
        if (payload) {
          const { groupSelected, tournamentImported } = payload;
          groupSelected && setGroupSelected(groupSelected);
          setLeagueSelected(tournamentImported);
          return;
        }
        if (!leagueSelected) {
          const storageLeagueSelected = localStorage.getItem(StorageConstants.LEAGUE_SELECTED);
          const existLeague = tournament.some(
            (item: TournamentResponse) => item.id === storageLeagueSelected
          );
          setLeagueSelected(existLeague ? storageLeagueSelected : tournament[0]?.id);
        }
      })
      .catch((err) => {
        loadingContext?.hide();
      });
  };

  const handleChangeGroup = (valueChange: string) => {
    loadingContext?.show();
    setGroupSelected(valueChange);
  };

  const handleChangeRound = (value: string | string[]) => {
    if (!leagueSelected || !value) {
      return;
    }
    setSearchParams({ ...params, roundId: value });
  };

  const handleChangeLeague = (value: string) => {
    loadingContext?.show();
    setLeagueSelected(value);
  };

  const handleShowDataSelected = (listOption: any, itemSelected: any) => {
    if (!listOption || listOption?.length <= 0 || !itemSelected) {
      return undefined;
    }
    const isShowLabel = listOption.some((option: any) => option.value === itemSelected);

    const item = isShowLabel ? itemSelected : undefined;

    return item;
  };

  //#endregion Handle Function

  return (
    <div className={cx('round_container')}>
      <div className={cx('round_content_top')}>
        <div className={cx('round_select_container')}>
          <span className={cx('select_label_table')}>{t('select_label_table')}: </span>
          <Select
            onChange={handleChangeLeague}
            className={cx('round_select')}
            placeholder={t('placeholder_select_tournament')}
            value={handleShowDataSelected(dataLeagues, leagueSelected)}
            options={dataLeagues}
          />

          <DropdownGroup defaultValue={groupSelected} onChange={handleChangeGroup} />
        </div>
        <Button onClick={() => setShowModalImport(true)}>{t('import_title')}</Button>
      </div>
      <div className={cx('round_content_bottom')}>
        {dataRounds.length > 0 ? (
          <Collapse
            items={dataRounds}
            expandIconPosition='end'
            onChange={handleChangeRound}
            activeKey={params.roundId}
          />
        ) : (
          <Empty />
        )}
      </div>
      {showModalImport && (
        <ModalImport
          showModal={showModalImport}
          setShowModal={setShowModalImport}
          onImportSuccess={callApiGetTournament}
        />
      )}
    </div>
  );
};

export default RoundList;
