import classNames from 'classnames/bind';

import { Select, Table as TableTeam, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IQueryGetTables } from '~/apis/table';
import DropdownGroup from '~/components/dropdownGroup/dropdownGroup';
import { LoadingContext } from '~/contexts/LoadingContext';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { selectListTable } from '~/thunks/table/tableSlice';
import { getTable } from '~/thunks/table/tableThunck';
import { selectListTournament } from '~/thunks/tournament/tournamentSlice';
import { getTournament } from '~/thunks/tournament/tournamentThunk';
import { convertDataSourceLeague } from '~/utils/helper';
import styles from './Table.module.scss';
import { StorageConstants } from '~/utils/enum';

const cx = classNames.bind(styles);
const styleColumnOrder = { color: 'green', fontWeight: 'bold' };

interface ITournament {
  label: string;
  value: string;
}

interface TournamentResponse {
  id: string;
  name: string;
  year: string;
}

interface IDataType {
  key: string;
  rank: number;
  played: number;
  won: number;
  drawn: number;
  lost: number;
  goalsScored: number;
  goalsConceded: number;
  goalDifference: number;
  point: number;
  team: string;
  fantasyPoint: number;
}

const Table = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);
  const dataSourceTables = useAppSelector(selectListTable);
  const dataSourceLeagues = useAppSelector(selectListTournament);

  const [leagueSelected, setLeagueSelected] = useState<string>();
  const [dataLeagues, setDataLeagues] = useState<ITournament[]>([]);
  const [dataTables, setDataTables] = useState<IDataType[]>([]);
  const [groupSelected, setGroupSelected] = useState<string>('');

  const columns: ColumnsType<IDataType> = [
    {
      title: '#',
      dataIndex: 'rank',
      className: cx('table_align_center'),
      width: '50px',
    },
    {
      title: t('header_table_team'),
      dataIndex: 'team',
      width: '35%',
    },
    {
      title: t('header_table_played'),
      dataIndex: 'played',
      // width: 'fit-content',
      className: cx('table_align_center'),
    },
    {
      title: t('header_table_won'),
      dataIndex: 'won',
      // width: 'fit-content',
      className: cx('table_align_center'),
    },
    {
      title: t('header_table_drawn'),
      dataIndex: 'drawn',
      // width: 'fit-content',
      className: cx('table_align_center'),
    },
    {
      title: t('header_table_lost'),
      dataIndex: 'lost',
      // width: 'fit-content',
      className: cx('table_align_center'),
    },
    {
      title: (
        <Tooltip title={t('header_table_gs_tooltip')}>
          <span>{t('header_table_gs')}</span>
        </Tooltip>
      ),
      dataIndex: 'goalsScored',
      // width: 'fit-content',
      className: cx('table_align_center'),
    },
    {
      title: (
        <Tooltip title={t('header_table_gc_tooltip')}>
          <span>{t('header_table_gc')}</span>
        </Tooltip>
      ),
      dataIndex: 'goalsConceded',
      // width: 'fit-content',
      className: cx('table_align_center'),
    },
    {
      title: (
        <Tooltip title={t('header_table_gd_tooltip')}>
          <span>{t('header_table_gd')}</span>
        </Tooltip>
      ),
      dataIndex: 'goalDifference',
      // width: 'fit-content',
      className: cx('table_align_center'),
    },
    {
      title:
        groupSelected !== '' ? (
          <span style={styleColumnOrder}>{t('header_table_point')}</span>
        ) : (
          t('header_table_point')
        ),
      dataIndex: 'point',
      // width: 'fit-content',
      className: cx('table_align_center'),
      render: (text) => {
        if (groupSelected !== '') {
          return <span style={styleColumnOrder}>{text}</span>;
        }
        return text;
      },
    },
    {
      title:
        groupSelected === '' ? (
          <span style={styleColumnOrder}>{t('header_table_fantasy_point')}</span>
        ) : (
          t('header_table_fantasy_point')
        ),
      dataIndex: 'fantasyPoint',
      width: '150px',
      className: cx('table_align_center'),
      render: (text) => {
        if (groupSelected === '') {
          return <span style={styleColumnOrder}>{text}</span>;
        }
        return text;
      },
    },
  ];

  useEffect(() => {
    handleGetTournament();
  }, []);

  useEffect(() => {
    if (!leagueSelected) {
      return;
    }

    localStorage.setItem(StorageConstants.LEAGUE_SELECTED, leagueSelected);
    handleGetTable({ tournamentId: leagueSelected, groupName: groupSelected });
  }, [leagueSelected, groupSelected]);

  useEffect(() => {
    if (!dataSourceLeagues || dataSourceLeagues?.length < 0) {
      return;
    }
    const dataSource = convertDataSourceLeague(dataSourceLeagues);
    setDataLeagues(dataSource);
  }, [dataSourceLeagues]);

  useEffect(() => {
    if (!dataSourceTables || dataSourceTables?.length <= 0) {
      setDataTables([]);
      return;
    }
    const dataSource = dataSourceTables?.map((data: any, index: number) => ({
      key: data?.id,
      rank: index + 1,
      played: Number(data?.win) + Number(data?.lose) + Number(data?.draw),
      won: data?.win,
      drawn: data?.draw,
      lost: data?.lose,
      goalsScored: data?.goalScore,
      goalsConceded: data?.goalConcede,
      goalDifference: data?.goalDifference,
      point: data?.rankingPoint,
      team: data?.name,
      fantasyPoint: Number(data?.totalPoint),
    }));

    setDataTables(dataSource);
  }, [dataSourceTables]);

  const customRow = (record: IDataType) => {
    if (record.rank === 1) {
      return cx('row_one');
    }
    if (record.rank === 2) {
      return cx('row_two');
    }
    if (record.rank === 3) {
      return cx('row_three');
    }

    return '';
  };

  //#region Handle Function
  const handleChangeGroup = (valueChange: string) => {
    loadingContext?.show();
    setGroupSelected(valueChange);
  };

  const handleChangeLeague = (value: string) => {
    loadingContext?.show();
    setLeagueSelected(value);
  };

  const handleGetTable = (payload: IQueryGetTables) => {
    dispatch(getTable(payload))
      .unwrap()
      .then((response) => {
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  const handleGetTournament = () => {
    loadingContext?.show();
    dispatch(getTournament())
      .unwrap()
      .then((res) => {
        const { tournament } = res.data;
        if (tournament && tournament?.length > 0) {
          const storageLeagueSelected = localStorage.getItem(StorageConstants.LEAGUE_SELECTED);
          const existLeague = tournament.some(
            (item: TournamentResponse) => item.id === storageLeagueSelected
          );
          const tournamentIdSelected = existLeague ? storageLeagueSelected : tournament[0]?.id;
          setLeagueSelected(tournamentIdSelected);
          return;
        }
        loadingContext?.hide();
      })
      .catch((err) => {
        loadingContext?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div className={cx('table_container')}>
      <div className={cx('table_select_container')}>
        <span className={cx('select_label_table')}>{t('select_label_table')}: </span>
        <Select
          onChange={handleChangeLeague}
          className={cx('table_select')}
          placeholder={t('placeholder_select_tournament')}
          value={leagueSelected}
          options={dataLeagues}
        />

        <DropdownGroup defaultValue={groupSelected} onChange={handleChangeGroup} />
      </div>
      <TableTeam
        columns={columns}
        dataSource={dataTables}
        className={cx('table_team')}
        pagination={false}
        scroll={{ y: 'calc(100vh - 316px)', x: 1500 }}
        rowClassName={customRow}
      />
    </div>
  );
};

export default Table;
