import { Column } from '@ant-design/plots';
import { Empty, Select } from 'antd';
import classNames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IQueryGetTables } from '~/apis/table';
import DropdownGroup from '~/components/dropdownGroup/dropdownGroup';
import { LoadingContext } from '~/contexts/LoadingContext';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { selectListTeam } from '~/thunks/totalPointTeam/totalPointTeamSlice';
import { getTotalPointTeam } from '~/thunks/totalPointTeam/totalPointTeamThunk';
import { selectListTournament } from '~/thunks/tournament/tournamentSlice';
import { getTournament } from '~/thunks/tournament/tournamentThunk';
import { styleTitleChart } from '~/utils/constants';
import { COLOR_GROUP, StorageConstants } from '~/utils/enum';
import { getEnumValue } from '~/utils/helper';
import styles from './TeamAnalytic.module.scss';
import i18n from '~/utils/i18n';

const cx = classNames.bind(styles);

interface Tournament {
  label: string;
  value: string;
}

interface TournamentResponse {
  id: string;
  name: string;
  year: string;
}

const TeamAnalytics = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);
  const dataSourceLeagues = useAppSelector(selectListTournament);
  const dataSourceTeams = useAppSelector(selectListTeam);

  const [dataChart, setDataChart] = useState<{ team: string; score: number }[]>([]);
  const [dataLeagues, setDataLeagues] = useState<Tournament[]>([]);
  const [leagueSelected, setLeagueSelected] = useState<string>();
  const [groupSelected, setGroupSelected] = useState<string>('');
  const [stateLanguage, setStateLanguage] = useState<string>('');

  const config = {
    data: dataChart,
    xField: 'team',
    yField: 'score',
    seriesField: 'group',
    color: ({ group }: any) => {
      const color: any = getEnumValue(COLOR_GROUP, group);
      return color;
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
      title: {
        text: t('analytics_field_team_name'),
        style: styleTitleChart,
      },
    },
    yAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
      title: {
        text: t('analytics_field_total_point'),
        style: styleTitleChart,
      },
    },
    legend: false as const,
  };
  const [stateConfig, setStateConfig] = useState(config);

  useEffect(() => {
    callApiGetTournament();
  }, []);

  useEffect(() => {
    if (!leagueSelected) {
      return;
    }

    localStorage.setItem(StorageConstants.LEAGUE_SELECTED, leagueSelected);
    callApiGetTeam({ tournamentId: leagueSelected, groupName: groupSelected });
  }, [groupSelected, leagueSelected]);

  useEffect(() => {
    setDataLeagues(
      dataSourceLeagues?.map((data: any) => ({
        value: data?.id,
        label: data?.name + ' ' + data?.year,
      })) || []
    );
  }, [dataSourceLeagues]);

  useEffect(() => {
    if (dataSourceTeams?.length > 0) {
      const dataChartConvert = dataSourceTeams.map((item: any) => {
        return {
          team: item.name,
          score: +item.totalPoint,
          group: item.groupName,
        };
      });
      setDataChart(dataChartConvert);
      return;
    }
    setDataChart([]);
  }, [dataSourceTeams]);

  const handleChangeGroup = (valueChange: string) => {
    loadingContext?.show();
    setGroupSelected(valueChange);
  };

  useEffect(() => {
    setStateConfig({
      ...config,
      xAxis: {
        label: {
          autoHide: false,
          autoRotate: true,
        },
        title: {
          text: t('analytics_field_team_name'),
          style: styleTitleChart,
        },
      },
      yAxis: {
        label: {
          autoHide: false,
          autoRotate: true,
        },
        title: {
          text: t('analytics_field_total_point'),
          style: styleTitleChart,
        },
      },
      data: dataChart,
    });
  }, [dataChart, stateLanguage]);

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      setStateLanguage(lng);
    });
  }, []);

  const callApiGetTournament = () => {
    loadingContext?.show();
    dispatch(getTournament())
      .unwrap()
      .then((res) => {
        const { tournament } = res.data;
        if (tournament && tournament?.length > 0) {
          const storageLeagueSelected = localStorage.getItem(StorageConstants.LEAGUE_SELECTED);
          const existLeague = tournament.some(
            (item: TournamentResponse) => item.id === storageLeagueSelected
          );
          const tournamentIdSelected = existLeague ? storageLeagueSelected : tournament[0]?.id;
          setLeagueSelected(tournamentIdSelected);
          return;
        }
        loadingContext?.hide();
      })
      .catch((err) => {
        loadingContext?.hide();
      });
  };

  const callApiGetTeam = (payload: IQueryGetTables) => {
    dispatch(getTotalPointTeam(payload))
      .unwrap()
      .then((response) => {
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  const handleChangeTournament = (newValue: string) => {
    loadingContext?.show();
    setLeagueSelected(newValue);
  };

  return (
    <div className={cx('analytic_container')}>
      <div className={cx('analytic_content_top')}>
        <div className={cx('analytic_select_container')}>
          <span>{t('select_label_table')}: </span>
          <Select
            className={cx('analytic_select')}
            value={leagueSelected}
            dropdownStyle={{
              maxHeight: 400,
              overflow: 'auto',
            }}
            placeholder={t('placeholder_select_tournament')}
            onChange={(value: any) => handleChangeTournament(value)}
            options={dataLeagues}
          />

          <DropdownGroup defaultValue={groupSelected} onChange={handleChangeGroup} />
        </div>
      </div>
      {!loadingContext?.isLoading && (
        <div className={cx('analytic_content_bottom')}>
          {dataChart?.length > 0 ? (
            <div className={cx('analytic_content_chart')}>
              <Column {...stateConfig} />
            </div>
          ) : (
            <Empty className={cx('analytic_content_empty')} />
          )}
        </div>
      )}
    </div>
  );
};

export default TeamAnalytics;
