import axiosClient from './axiosClient';

export interface IQueryGetTables {
  tournamentId: string;
  groupName?: string;
}

const tableApi = {
  getTable(params: IQueryGetTables) {
    const { tournamentId, groupName } = params;
    let url = `/teams/ranking?tournamentId=${tournamentId}`;
    if (groupName) {
      url += `&groupName=${groupName}`;
    }
    return axiosClient.get(url);
  },
};

export default tableApi;
