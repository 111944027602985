import Table, { ColumnsType } from 'antd/es/table';
import classNames from 'classnames/bind';
import styles from './TableMatch.module.scss';
import { Link } from 'react-router-dom';
import { AdminProtectedPath, RouterPaths } from '~/utils/enum';
import { useState, useEffect } from 'react';

interface DataType {
  key: string;
  name_team_1: string;
  name_team_2: string;
  total_point_team_1: number;
  total_point_team_2: number;
  goal_team_1: number;
  goal_team_2: number;
}

const cx = classNames.bind(styles);

const TableMatch = ({ matches }: { matches: Array<any> }) => {
  const [dataMatches, setDataMatches] = useState<DataType[]>([]);

  useEffect(() => {
    matches?.length > 0 &&
      setDataMatches(
        matches.map((match: any) => ({
          key: match?.id,
          name_team_1: match?.leftTeam?.name,
          name_team_2: match?.rightTeam?.name,
          total_point_team_1: Number(match?.leftTeam?.totalPoint),
          total_point_team_2: Number(match?.rightTeam?.totalPoint),
          goal_team_1: match?.leftTeam?.score,
          goal_team_2: match?.rightTeam?.score,
        }))
      );
  }, [matches]);

  const columns: ColumnsType<DataType> = [
    {
      render: (_, record) => (
        <Link className={cx('match_content_info')} to={`/${RouterPaths.ADMIN}${AdminProtectedPath.MATCH_DETAIL.path}?id=${record.key}`}>
          <div className={cx('team_1')}>
            <span>{record.total_point_team_1}</span>
            <span className={cx('name_team')}>{record.name_team_1}</span>
          </div>
          <div className={cx('goal')}>
            <div className={cx('goal_content')}>
              <span>{record.goal_team_1}</span>
              <span className={cx('goal_dash')}>-</span>
              <span>{record.goal_team_2}</span>
            </div>
          </div>
          <div className={cx('team_2')}>
            <span className={cx('name_team')}>{record.name_team_2}</span>
            <span>{record.total_point_team_2}</span>
          </div>
        </Link>
      ),
    },
  ];

  return <Table columns={columns} dataSource={dataMatches} pagination={false} showHeader={false} rowClassName={cx('match_row')} />;
};

export default TableMatch;
