export const LOGIN = 'auth/login';
export const ROUNDS_IMPORT = 'rounds/import';
export const ROUNDS_GET = 'rounds/get';
export const ROUNDS_GET_SELECTION = 'rounds/getSelection';
export const TOURNAMENT_GET = 'tournament/get';
export const MATCH_GET = 'match/get';
export const TABLE_GET = 'table/get';
export const TOTAL_POINT_TEAM_GET = 'totalPointTeam/get';
export const TEAM_GET = 'team/get';
export const TOTAL_POINT_PLAYER_GET = 'totalPointPlayer/get';
