import { Button, Collapse, Empty, Select } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import classNames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { IQueryGetRounds } from '~/apis/round';
import DropdownGroup from '~/components/dropdownGroup/dropdownGroup';
import { LoadingContext } from '~/contexts/LoadingContext';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { selectListRound } from '~/thunks/round/roundSlice';
import { getRound } from '~/thunks/round/roundThunk';
import { selectListTournament } from '~/thunks/tournament/tournamentSlice';
import { getTournament } from '~/thunks/tournament/tournamentThunk';
import { convertDataSourceLeague, convertUpdated, objectIsEmpty } from '~/utils/helper';
import i18n from '~/utils/i18n';
import styles from './Group.module.scss';
import { RouterPaths, StorageConstants } from '~/utils/enum';

const cx = classNames.bind(styles);

interface ITournament {
  label: string;
  value: string;
}

interface TournamentResponse {
  id: string;
  name: string;
  year: string;
}

interface IRound {
  label: JSX.Element;
  children: JSX.Element;
}

interface IDataTable {
  key: string;
  name_team_1: string;
  name_team_2: string;
  total_point_team_1: number;
  total_point_team_2: number;
  goal_team_1: number;
  goal_team_2: number;
}

const TableMatch = ({ matches }: { matches: Array<any> }) => {
  const [dataMatches, setDataMatches] = useState<IDataTable[]>([]);

  useEffect(() => {
    matches?.length > 0 &&
      setDataMatches(
        matches.map((match: any) => ({
          key: match?.id,
          name_team_1: match?.leftTeam?.name,
          name_team_2: match?.rightTeam?.name,
          total_point_team_1: Number(match?.leftTeam?.totalPoint),
          total_point_team_2: Number(match?.rightTeam?.totalPoint),
          goal_team_1: match?.leftTeam?.score,
          goal_team_2: match?.rightTeam?.score,
        }))
      );
  }, [matches]);

  const columns: ColumnsType<IDataTable> = [
    {
      render: (_, record) => (
        <div className={cx('match_content_info')}>
          <div className={cx('team_1')}>
            <span>{record.total_point_team_1}</span>
            <span className={cx('name_team')}>{record.name_team_1}</span>
          </div>
          <div className={cx('goal')}>
            <div className={cx('goal_content')}>
              <span>{record.goal_team_1}</span>
              <span className={cx('goal_dash')}>-</span>
              <span>{record.goal_team_2}</span>
            </div>
          </div>
          <div className={cx('team_2')}>
            <span className={cx('name_team')}>{record.name_team_2}</span>
            <span>{record.total_point_team_2}</span>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={dataMatches}
      pagination={false}
      showHeader={false}
      rowClassName={cx('match_row')}
    />
  );
};

const Group = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);

  const dataSourceLeagues = useAppSelector(selectListTournament);
  const dataSourceRounds = useAppSelector(selectListRound);

  const [leagueSelected, setLeagueSelected] = useState<string>(
    !objectIsEmpty(params) ? params?.tournamentId : ''
  );
  const [groupSelected, setGroupSelected] = useState<string>(
    !objectIsEmpty(params) ? params?.group : ''
  );
  const [dataLeagues, setDataLeagues] = useState<ITournament[]>([]);
  const [dataRounds, setDataRounds] = useState<IRound[]>([]);
  const [stateLanguage, setStateLanguage] = useState<string>('');

  //#region useEffect
  useEffect(() => {
    callApiGetTournament();
  }, []);

  useEffect(() => {
    if (!leagueSelected && !groupSelected) {
      return;
    }

    if (leagueSelected) {
      localStorage.setItem(StorageConstants.LEAGUE_SELECTED, leagueSelected);
    }

    callApiGetRound({ tournamentId: +leagueSelected, groupName: groupSelected });
    setSearchParams({ ...params, tournamentId: leagueSelected, group: groupSelected });
  }, [groupSelected, leagueSelected]);

  useEffect(() => {
    if (dataSourceLeagues && dataSourceLeagues?.length > 0) {
      const dataSource = convertDataSourceLeague(dataSourceLeagues);
      setDataLeagues(dataSource);
    }
  }, [dataSourceLeagues]);

  useEffect(() => {
    if (dataSourceRounds.length > 0) {
      const dataRounds = dataSourceRounds.map((data: any) => {
        return {
          label: (
            <LabelRound
              nameOfRound={`${t('admin_group_day')} ${data?.day}`}
              dateOfRound={convertUpdated(data?.createdAt)}
            />
          ),
          children: <TableMatch matches={data?.matches} />,
        };
      });
      setDataRounds(dataRounds);
      return;
    }
    setDataRounds([]);
  }, [dataSourceRounds, stateLanguage]);

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      setStateLanguage(lng);
    });
  }, []);
  //#endregion useEffect

  //#region Handle Function
  const LabelRound = ({
    nameOfRound,
    dateOfRound,
  }: {
    nameOfRound: string;
    dateOfRound: string;
  }) => {
    return (
      <div className={cx('round_collapse_header')}>
        <div className={cx('name_of_round')}>{nameOfRound}</div>
        <div>{dateOfRound}</div>
      </div>
    );
  };

  const callApiGetRound = (payload: IQueryGetRounds) => {
    dispatch(getRound(payload))
      .unwrap()
      .then((response) => {
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  const callApiGetTournament = (payload?: {
    groupSelected: string;
    tournamentImported: string;
  }) => {
    loadingContext?.show();
    dispatch(getTournament())
      .unwrap()
      .then((res) => {
        const { tournament } = res.data;
        if (!tournament || tournament.length <= 0) {
          loadingContext?.hide();
          return;
        }
        if (payload) {
          const { groupSelected, tournamentImported } = payload;
          groupSelected && setGroupSelected(groupSelected);
          setLeagueSelected(tournamentImported);
          return;
        }
        if (!leagueSelected) {
          const storageLeagueSelected = localStorage.getItem(StorageConstants.LEAGUE_SELECTED);
          const existLeague = tournament.some(
            (item: TournamentResponse) => item.id === storageLeagueSelected
          );
          setLeagueSelected(existLeague ? storageLeagueSelected : tournament[0]?.id);
        }
      })
      .catch((err) => {
        loadingContext?.hide();
      });
  };

  const handleChangeGroup = (valueChange: string) => {
    loadingContext?.show();
    setGroupSelected(valueChange);
  };

  const handleChangeRound = (value: string | string[]) => {
    if (!leagueSelected || !value) {
      return;
    }
    setSearchParams({ ...params, roundId: value });
  };

  const handleChangeLeague = (value: string) => {
    loadingContext?.show();
    setLeagueSelected(value);
  };

  const handleShowDataSelected = (listOption: any, itemSelected: any) => {
    if (!listOption || listOption?.length <= 0 || !itemSelected) {
      return undefined;
    }
    const isShowLabel = listOption.some((option: any) => option.value === itemSelected);

    const item = isShowLabel ? itemSelected : undefined;

    return item;
  };

  //#endregion Handle Function

  return (
    <div className={cx('publicLayout_content')}>
      <div className={cx('round_container')}>
        <div className={cx('round_content_top')}>
          <div className={cx('round_select_container')}>
            <span className={cx('select_label_table')}>{t('select_label_table')}: </span>
            <Select
              onChange={handleChangeLeague}
              className={cx('round_select')}
              placeholder={t('placeholder_select_tournament')}
              value={handleShowDataSelected(dataLeagues, leagueSelected)}
              options={dataLeagues}
            />

            <DropdownGroup defaultValue={groupSelected} onChange={handleChangeGroup} />
          </div>
          <Link className={cx('round_link_button')} to={'/' + RouterPaths.PUBLIC.TABLE}>
            <Button block>{t('link_to_page_public_table')}</Button>
          </Link>
        </div>
        <div className={cx('round_content_bottom')}>
          {dataRounds.length > 0 ? (
            <Collapse
              items={dataRounds}
              expandIconPosition='end'
              onChange={handleChangeRound}
              activeKey={params.roundId}
            />
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </div>
  );
};

export default Group;
