import { useEffect, useState } from 'react';

import { Select } from 'antd';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { IDataSelect } from '~/pages/round/Round';
import { GROUPS } from '~/utils/constants.common';
import i18n from '~/utils/i18n';
import styles from './dropdownGroup.module.scss';
const cx = classNames.bind(styles);

interface DropdownGroupProps {
  hasAll?: boolean;
  onChange?: Function;
  defaultValue?: string;
  width?: number;
}

const DropdownGroup: React.FC<DropdownGroupProps> = ({
  hasAll = true,
  onChange,
  defaultValue,
  width = 200,
}) => {
  const { t } = useTranslation();
  const allGroupItem = { label: t('select_label_all_group'), value: '' };
  const [dataSourceGroups, setDataSourceGroups] = useState<IDataSelect[]>(() => {
    const dataSourceGroup = GROUPS.map((item) => {
      return {
        ...item,
        label: `${t('dropdown_label_group')} ${item.value}`,
      };
    });
    if (hasAll) {
      dataSourceGroup.push(allGroupItem);
    }
    return dataSourceGroup;
  });

  //#region useEffect
  useEffect(() => {
    i18n.on('languageChanged', () => {
      const allGroupTranslated = { label: t('select_label_all_group'), value: '' };
      setDataSourceGroups(() => {
        const dataSourceGroup = GROUPS.map((item) => {
          return {
            ...item,
            label: `${t('dropdown_label_group')} ${item.value}`,
          };
        });
        if (hasAll) {
          dataSourceGroup.push(allGroupTranslated);
        }
        return dataSourceGroup;
      });
    });
  }, []);
  //#endregion useEffect

  //#region Handle Function
  const handleChangeGroup = (value: string) => {
    onChange && onChange(value);
  };
  //#endregion Handle Function

  return (
    <>
      <span className={cx('select_label_table')}>{t('select_label_group')}: </span>
      <Select
        onChange={handleChangeGroup}
        className={cx('table_select')}
        placeholder={t('placeholder_select_round')}
        value={defaultValue}
        options={dataSourceGroups}
        style={{ width }}
      />
    </>
  );
};

export default DropdownGroup;
