import { createAsyncThunk } from '@reduxjs/toolkit';

import authApi from '~/apis/auth';
import { LoginPayload } from '~/data/auth';
import { LOGIN } from '~/utils/actionType';
import { StorageConstants } from '~/utils/enum';

export const login = createAsyncThunk(LOGIN, async (payload: LoginPayload, { rejectWithValue }) => {
  try {
    const response = await authApi.login(payload);
    localStorage.setItem(StorageConstants.ACCESS_TOKEN, response?.data?.data?.accessToken);
    localStorage.setItem(StorageConstants.USER, JSON.stringify(response?.data?.data?.user));
    localStorage.setItem(StorageConstants.REFRESH_TOKEN, response?.data?.data?.refreshToken);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
