import { urlApiTeam } from '~/utils/constants';
import axiosClient from './axiosClient';

export interface IQueryParamGetTeams {
  tournamentId: string;
  groupName?: string;
}

const teamApi = {
  getTeam(payload: IQueryParamGetTeams) {
    const { tournamentId, groupName } = payload;
    let url = `${urlApiTeam}=${tournamentId}`;
    if (groupName) {
      url += `&groupName=${groupName}`;
    }
    return axiosClient.get(url);
  },
};

export default teamApi;
