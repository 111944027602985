import { Bar } from '@ant-design/plots';
import { Empty, Pagination, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import classNames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IQueryParamGetTotalPointPlayer } from '~/apis/analyticsPlayer';
import { IQueryParamGetTeams } from '~/apis/team';
import DropdownGroup from '~/components/dropdownGroup/dropdownGroup';
import { LoadingContext } from '~/contexts/LoadingContext';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { selectListAllTeam } from '~/thunks/team/teamSlice';
import { getTeam } from '~/thunks/team/teamThunk';
import { selectListPlayer } from '~/thunks/totalPointPlayer/totalPointPlayerSlice';
import { getTotalPointPlayer } from '~/thunks/totalPointPlayer/totalPointPlayerThuck';
import { selectListTournament } from '~/thunks/tournament/tournamentSlice';
import { getTournament } from '~/thunks/tournament/tournamentThunk';
import { typeDataTotalPointPlayers } from '~/utils/constants';
import { convertDataSourceLeague, convertDataSourceTeam } from '~/utils/helper';
import i18n from '~/utils/i18n';
import styles from './PlayerAnalytic.module.scss';
import { StorageConstants } from '~/utils/enum';

const cx = classNames.bind(styles);

interface IDataSelect {
  label: string;
  value: string;
}

interface TournamentResponse {
  id: string;
  name: string;
  year: string;
}

interface IDataTable {
  rank: number;
  name: string;
  club: string;
  point: number;
  key: string;
}

interface IDataChart {
  name: string;
  point: number;
}

const PlayerAnalytics = () => {
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const allTeam = { label: t('select_label_all_team'), value: '' };

  const dataSourceLeagues = useAppSelector(selectListTournament);
  const dataSourceTeams = useAppSelector(selectListAllTeam);
  const dataSourcePlayers = useAppSelector(selectListPlayer);
  const { result, type, pagination } = dataSourcePlayers;

  const [leagueSelected, setLeagueSelected] = useState<string>();
  const [teamSelected, setTeamSelected] = useState<string>('');
  const [dataLeagues, setDataLeagues] = useState<IDataSelect[]>();
  const [groupSelected, setGroupSelected] = useState<string>('');
  const [pageSelected, setPageSelected] = useState<number>();
  const [limitSelected, setLimitSelected] = useState<number>();
  const [dataTeams, setDataTeams] = useState<IDataSelect[]>([allTeam]);
  const [componentData, setComponentData] = useState<any>(<></>);
  const [languageChanged, setLanguageChanged] = useState<string>('');
  const columns: ColumnsType<IDataTable> = [
    {
      title: '#',
      dataIndex: 'rank',
      width: '80px',
      className: cx('table_align_center'),
    },
    {
      title: t('analytics_player_header_name'),
      dataIndex: 'name',
      width: '200px',
    },
    {
      title: t('analytics_player_header_club'),
      dataIndex: 'club',
      width: '400px',
    },
    {
      title: t('analytics_player_header_point'),
      dataIndex: 'point',
      width: '80px',
      className: cx('table_align_center'),
    },
  ];

  //#region Handle Function
  const handleChangeLeague = (value: string) => {
    if (!dataLeagues || dataLeagues?.length <= 0) {
      return;
    }
    loadingContext?.show();
    setLeagueSelected(value);
    setTeamSelected('');
    setPageSelected(0);
    setLimitSelected(0);
  };

  const handleChangeTeam = (value: string) => {
    if (!dataTeams || dataTeams?.length <= 0 || !leagueSelected) {
      return;
    }
    loadingContext?.show();
    setTeamSelected(value);
  };

  const handleGetTournament = () => {
    loadingContext?.show();
    dispatch(getTournament())
      .unwrap()
      .then((res) => {
        const { tournament } = res.data;
        if (tournament && tournament?.length > 0) {
          const storageLeagueSelected = localStorage.getItem(StorageConstants.LEAGUE_SELECTED);
          const existLeague = tournament.some(
            (item: TournamentResponse) => item.id === storageLeagueSelected
          );
          const tournamentIdSelected = existLeague ? storageLeagueSelected : tournament[0]?.id;
          setLeagueSelected(tournamentIdSelected);
        } else {
          loadingContext?.hide();
        }
      })
      .catch((err) => {
        loadingContext?.hide();
      });
  };

  const handleGetTeam = (payload: IQueryParamGetTeams) => {
    dispatch(getTeam(payload))
      .unwrap()
      .then((res) => {
        const { teams } = res.data;
        if (teams && teams?.length > 0) {
          setTeamSelected(allTeam.value);
        } else {
          loadingContext?.hide();
        }
      })
      .catch((err) => {
        loadingContext?.hide();
      });
  };

  const handleGetDataTotalPointPlayer = (payload: IQueryParamGetTotalPointPlayer) => {
    dispatch(getTotalPointPlayer(payload))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
      })
      .catch((err) => {
        loadingContext?.hide();
      });
  };

  const handleChangePagination = (page: number, limit: number) => {
    if (!leagueSelected) {
      return;
    }
    loadingContext?.show();
    setPageSelected(page);
    setLimitSelected(limit);
  };

  const handleChangeGroup = (valueChange: string) => {
    loadingContext?.show();
    setGroupSelected(valueChange);
    setTeamSelected('');
    setPageSelected(0);
    setLimitSelected(0);
  };
  //#endregion Handle Function

  useEffect(() => {
    handleGetTournament();
  }, []);

  useEffect(() => {
    if (!leagueSelected) {
      return;
    }
    handleGetDataTotalPointPlayer({
      tournamentId: leagueSelected!,
      teamId: teamSelected,
      groupName: groupSelected,
      page: pageSelected,
      limit: limitSelected,
    });
  }, [leagueSelected, teamSelected, groupSelected, pageSelected, limitSelected]);

  useEffect(() => {
    if (!leagueSelected) {
      return;
    }

    localStorage.setItem(StorageConstants.LEAGUE_SELECTED, leagueSelected);
    handleGetTeam({
      tournamentId: leagueSelected,
      groupName: groupSelected,
    });
  }, [leagueSelected, groupSelected]);

  useEffect(() => {
    const dataSource = convertDataSourceLeague(dataSourceLeagues);
    setDataLeagues(dataSource);
  }, [dataSourceLeagues]);

  useEffect(() => {
    const dataSource = convertDataSourceTeam(dataSourceTeams);
    setDataTeams([allTeam, ...dataSource]);
  }, [dataSourceTeams]);

  useEffect(() => {
    if (!result || result?.length <= 0) {
      setComponentData(<Empty />);
      return;
    }

    switch (type) {
      case typeDataTotalPointPlayers.table:
        const { page, limit } = pagination;
        const dataTableConvert: IDataTable[] = result?.map((item: any, index: number) => ({
          name: item?.name,
          rank: (page - 1) * limit + index + 1,
          club: item?.teamName,
          point: Number(item?.totalFantasyScore),
          key: item?.id,
        }));
        const componentTable = (
          <>
            <Table
              columns={columns}
              dataSource={dataTableConvert}
              scroll={{ y: 'calc(100vh - 390px)', x: 1000 }}
              pagination={false}
            />
            <Pagination
              total={pagination?.totalItems}
              pageSize={pagination?.limit}
              current={pagination?.page}
              onChange={handleChangePagination}
              className={cx('pagination')}
            />
          </>
        );
        setComponentData(componentTable);
        break;

      case typeDataTotalPointPlayers.chart:
        const dataChartConvert: IDataChart[] = result?.map((item: any) => ({
          name: item?.name,
          point: Number(item?.totalFantasyScore),
        }));
        const configPlayer = {
          data: dataChartConvert,
          xField: 'point',
          yField: 'name',
          height: window.innerHeight - 288,
          meta: {
            point: {
              alias: t('analytics_player_header_point'),
            },
          },
        };
        const componentChart = <Bar {...configPlayer} />;
        setComponentData(componentChart);
        break;

      default:
        setComponentData(<></>);
        break;
    }
  }, [result, type, pagination, languageChanged]);

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      setLanguageChanged(lng);
      const allRoundTranslated = { label: t('select_label_all_team'), value: '' };
      setDataTeams((prevState) => {
        const newList = [...prevState];
        newList.shift();
        newList.unshift(allRoundTranslated);
        return newList;
      });
    });
  }, []);

  return (
    <div className={cx('analyticsPlayer_container')}>
      <div className={cx('analyticsPlayer_content_top')}>
        <div className={cx('select_container')}>
          <span>{t('select_label_table')}: </span>
          <Select
            onChange={handleChangeLeague}
            className={cx('tour_select')}
            placeholder={t('placeholder_select_tournament')}
            value={leagueSelected}
            options={dataLeagues}
          />

          <DropdownGroup defaultValue={groupSelected} onChange={handleChangeGroup} />

          <span>{t('select_label_team')}: </span>
          <Select
            onChange={handleChangeTeam}
            className={cx('team_select')}
            placeholder={t('placeholder_select_team')}
            value={teamSelected}
            options={dataTeams}
          />
        </div>
      </div>
      {!loadingContext?.isLoading && (
        <div className={cx('analyticsPlayer_content_bottom')}>{componentData}</div>
      )}
    </div>
  );
};

export default PlayerAnalytics;
