import { createSlice } from '@reduxjs/toolkit';
import { getTotalPointPlayer } from './totalPointPlayerThuck';

interface ITotalPointPlayerState {
  totalPointPlayers: Array<any> | null | undefined;
}

const initialState: ITotalPointPlayerState = {
  totalPointPlayers: [],
};

const totalPointPlayerSlice = createSlice({
  name: 'analyticPlayer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTotalPointPlayer.fulfilled, (state, action) => {
      state.totalPointPlayers = action.payload.data.totalPointPlayers;
    });
  },
});

export const totalPointPlayerActions = totalPointPlayerSlice.actions;

export const selectListPlayer = (state: any) => state.analyticPlayer.totalPointPlayers;

const totalPointPlayerReducer = totalPointPlayerSlice.reducer;
export default totalPointPlayerReducer;
