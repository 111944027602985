import { createAsyncThunk } from '@reduxjs/toolkit';
import totalPointPlayer from '~/apis/analyticsPlayer';
import { TOTAL_POINT_PLAYER_GET } from '~/utils/actionType';

export const getTotalPointPlayer = createAsyncThunk(TOTAL_POINT_PLAYER_GET, async (payload: any, { rejectWithValue }) => {
  try {
    const response = await totalPointPlayer.getTotalPointPlayer(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
