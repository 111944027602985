import { createAsyncThunk } from '@reduxjs/toolkit';
import matchApi from '~/apis/match';
import { MATCH_GET } from '~/utils/actionType';

export const getDetailMatch = createAsyncThunk(MATCH_GET, async (payload: any, { rejectWithValue }) => {
  try {
    const response = await matchApi.getDetailMatch(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
