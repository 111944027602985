import axiosClient from './axiosClient';

interface ParamType {
  id: string;
}

const matchApi = {
  getDetailMatch(params: ParamType) {
    const { id } = params;
    const url = `matches/${id}`;

    return axiosClient.get(url);
  },
};

export default matchApi;
