import { DetailMatch, RoundList, Table } from '~/pages';
import { PlayerAnalytics, TeamAnalytics } from '~/pages/analytic';

export const panelRouters = [
  {
    name: 'round_title',
    path: '/groups',
    layout: '/admin',
    component: RoundList,
  },
  {
    name: 'detail_match_title',
    path: '/groups/match',
    layout: '/admin',
    component: DetailMatch,
  },
  {
    name: 'table_title',
    path: '/tables',
    layout: '/admin',
    component: Table,
  },
  {
    name: 'sidebar_team_title',
    path: '/analytics/teams',
    layout: '/admin',
    component: TeamAnalytics,
  },
  {
    name: 'sidebar_player_title',
    path: '/analytics/players',
    layout: '/admin',
    component: PlayerAnalytics,
  },
];
