import BackgroundLogin from '~/assets/imgs/login_image.jpeg';
import LogoSideBar from '~/assets/icons/logo_fife.png';
import IconChevronLeft from '~/assets/icons/ic_chevron_left.svg';
import IconChevronRight from '~/assets/icons/ic_chevron_right.svg';

export const image = {
  backgroundLogin: BackgroundLogin,
};

export const icon = {
  logoSideBar: LogoSideBar,
  iconChevronLeft: IconChevronLeft,
  iconChevronRight: IconChevronRight,
};
