import { createAsyncThunk } from '@reduxjs/toolkit';
import roundApi, { IQueryGetRounds } from '~/apis/round';
import { ROUNDS_GET, ROUNDS_GET_SELECTION, ROUNDS_IMPORT } from '~/utils/actionType';

export const importFileExcel = createAsyncThunk(ROUNDS_IMPORT, async (payload: any, { rejectWithValue }) => {
  try {
    const response = await roundApi.importFileExcel(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getRound = createAsyncThunk(ROUNDS_GET, async (payload: IQueryGetRounds, { rejectWithValue }) => {
  try {
    const response = await roundApi.getRound(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getRoundsSelection = createAsyncThunk(ROUNDS_GET_SELECTION, async (payload: any, { rejectWithValue }) => {
  try {
    const response = await roundApi.getRoundsSelection(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
