import { useContext, useEffect, useState } from 'react';

import { Tabs } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { LoadingContext } from '~/contexts/LoadingContext';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { selectDetailMatch } from '~/thunks/match/matchSlice';
import { getDetailMatch } from '~/thunks/match/matchThunk';
import { columnKeyMatch, statusPlayer } from '~/utils/constants';
import { BENCH_HEADER, REWARD_POINTS } from '~/utils/enum';
import { convertTactic, convertUpdated } from '~/utils/helper';
import styles from './DetailMatch.module.scss';
import i18n from '~/utils/i18n';
const cx = classNames.bind(styles);

interface DataType {
  key: string;
  position: string;
  fullName: string;
  team: string;
  originalPoint?: number | null;
  fantasyPoint?: number | null;
  isPlay?: boolean | null;
}
interface DataTypeResult {
  tournament: string;
  date: string;
  team_1: string;
  team_1_map: string;
  team_1_goal: string;
  team_2: string;
  team_2_map: string;
  team_2_goal: string;
}

const DetailMatch = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);
  const [searchParams] = useSearchParams();
  const query = searchParams.get('id');
  const dataSourceMatch = useAppSelector(selectDetailMatch);
  const [dataResult, setDataResult] = useState<DataTypeResult>({
    tournament: '',
    date: '',
    team_1: '',
    team_1_map: '',
    team_1_goal: '',
    team_2: '',
    team_2_map: '',
    team_2_goal: '',
  });
  const [teams, setTeams] = useState<string[]>([]);
  const [dataMatchTeam1, setDataMatchTeam1] = useState<DataType[]>([]);
  const [dataMatchTeam2, setDataMatchTeam2] = useState<DataType[]>([]);
  const [stateLanguage, setStateLanguage] = useState<string>('');
  const [rewardPointsKey] = useState(() => REWARD_POINTS.map((reward) => reward.key));
  const [headerBench, setHeaderBench] = useState({
    key: BENCH_HEADER,
    position: '',
    fullName: '',
    team: t('match_bench'),
    originalPoint: null,
    fantasyPoint: null,
  });
  const columns: ColumnsType<DataType> = [
    {
      title: t('header_match_table_position'),
      dataIndex: 'position',
      width: '80px',
      key: columnKeyMatch.position,
    },
    {
      title: t('header_match_table_fullName'),
      dataIndex: 'fullName',
      key: columnKeyMatch.name,
    },
    {
      title: t('header_match_table_team'),
      dataIndex: 'team',
      width: '30%',
      key: columnKeyMatch.team,
      render: (text: string, record: DataType) => {
        if (record.key === BENCH_HEADER) {
          return {
            children: <span>{text}</span>,
            props: {
              colSpan: 5,
              className: cx('bench_td'),
            },
          };
        }
        if (rewardPointsKey.includes(record.key)) {
          return {
            children: <span>{text}</span>,
            props: {
              colSpan: 4,
              className: cx('reward_td'),
            },
          };
        }
        return <span>{text} </span>;
      },
    },
    {
      title: t('header_match_table_original_point'),
      key: columnKeyMatch.original,
      dataIndex: 'originalPoint',
      width: '150px',
    },
    {
      title: t('header_match_table_fantasy_point'),
      dataIndex: 'fantasyPoint',
      width: '150px',
      key: columnKeyMatch.fantasy,
      render: (text: string) => ({
        children: <span className={cx('color_green')}>{text}</span>,
      }),
    },
  ];
  const customRow: any = (record: DataType) => {
    if (record.key === BENCH_HEADER) {
      return cx('bench');
    }
    if (rewardPointsKey.includes(record.key)) {
      return cx('reward');
    }

    if (!record.isPlay) {
      return cx('no_play');
    }

    return '';
  };

  const handleConvertData = (data: any) => {
    let teamStarting: DataType[] = [];
    let teamSubstitute: DataType[] = [];
    let rewardPointsDataTeam: DataType[] = [];

    data?.players?.forEach((player: any) => {
      const convertPlayer = {
        key: player?.id,
        position: player?.position,
        fullName: player?.name,
        team: player?.realTeam,
        originalPoint: Number(player?.originalScore),
        fantasyPoint: Number(player?.fantasyScore),
        isPlay: player?.isPlay,
      };
      switch (player?.status) {
        case statusPlayer.starting:
          teamStarting.push(convertPlayer);
          break;
        case statusPlayer.substitute:
          teamSubstitute.push(convertPlayer);
          break;
        default:
          break;
      }
    });

    rewardPointsDataTeam = REWARD_POINTS.map((reward) => {
      if (data[reward.key] > 0) {
        return {
          key: reward.key,
          position: '',
          fullName: '',
          team: t(reward.label),
          originalPoint: null,
          fantasyPoint: Number(data[reward.key]?.toString()),
        };
      }
      return null;
    }).filter((item) => item !== null) as DataType[];

    return [...teamStarting, headerBench, ...teamSubstitute, ...rewardPointsDataTeam];
  };

  const handleGetDetailMatch = (query: string | null) => {
    loadingContext?.show();

    dispatch(getDetailMatch({ id: query }))
      .unwrap()
      .then((response) => {
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  useEffect(() => {
    handleGetDetailMatch(query);
  }, [query]);

  useEffect(() => {
    if (dataSourceMatch) {
      const { information, teams: teamSource } = dataSourceMatch;

      //convert show data info detail match
      if (information) {
        setDataResult({
          tournament: information?.tournament?.name + ' ' + information?.tournament?.year,
          date: convertUpdated(information?.createdAt),
          team_1: information?.leftTeam?.name,
          team_1_map: convertTactic(information?.leftTeam?.tactic),
          team_1_goal: information?.leftTeam?.score,
          team_2: information?.rightTeam?.name,
          team_2_map: convertTactic(information?.rightTeam?.tactic),
          team_2_goal: information?.rightTeam?.score,
        });
      }

      if (teamSource) {
        //convert show name of teams
        setTeams(teamSource?.map((team: any) => team?.name));

        //convert show information team 1
        const team1: DataType[] = teamSource?.length > 0 ? handleConvertData(teamSource[0]) : [];

        setDataMatchTeam1(team1);

        //convert show information team 2
        const team2: DataType[] = teamSource?.length > 0 ? handleConvertData(teamSource[1]) : [];

        setDataMatchTeam2(team2);
      }
    }
  }, [dataSourceMatch, stateLanguage]);

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      setStateLanguage(lng);
      setHeaderBench((prevState) => ({ ...prevState, team: t('match_bench') }));
    });
  }, []);

  const TabChildren = ({ dataMatchTeam }: { dataMatchTeam: DataType[] }) => {
    return (
      <div className={cx('detailMatch_bottom_content')}>
        <Table
          className={cx('detailMatch_table')}
          columns={columns}
          dataSource={dataMatchTeam}
          pagination={false}
          scroll={{ x: 1050 }}
          rowClassName={customRow}
        />
      </div>
    );
  };

  return (
    <div id='detailMatch' className={cx('detailMatch_container')}>
      <div className={cx('detailMatch_top')}>
        <h1>{dataResult.tournament}</h1>
        <span className={cx('detailMatch_date')}>{dataResult.date}</span>
        <div className={cx('detailMatch_result')}>
          <div className={cx('team_1')}>
            <span className={cx('team_name')}>{dataResult.team_1}</span>
            <span>{dataResult.team_1_map}</span>
          </div>
          <div className={cx('goal')}>
            <div>{dataResult.team_1_goal}</div>
            {dataResult.team_1 && <div className={cx('goal_dash')}>-</div>}
            <div>{dataResult.team_2_goal}</div>
          </div>
          <div className={cx('team_2')}>
            <span className={cx('team_name')}>{dataResult.team_2}</span>
            <span>{dataResult.team_2_map}</span>
          </div>
        </div>
      </div>

      <div className={cx('detailMatch_bottom')}>
        <Tabs
          defaultActiveKey='1'
          centered
          items={teams?.map((team, i) => {
            const id = String(i + 1);
            const dataMatchTeam: DataType[] = id === '1' ? dataMatchTeam1 : dataMatchTeam2;
            return {
              label: team,
              key: id,
              children: <TabChildren dataMatchTeam={dataMatchTeam} />,
            };
          })}
        />
      </div>
    </div>
  );
};

export default DetailMatch;
