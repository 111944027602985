import { createAsyncThunk } from '@reduxjs/toolkit';
import tableApi, { IQueryGetTables } from '~/apis/table';
import { TABLE_GET } from '~/utils/actionType';

export const getTable = createAsyncThunk(TABLE_GET, async (payload: IQueryGetTables, { rejectWithValue }) => {
  try {
    const response = await tableApi.getTable(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
