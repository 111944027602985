import { createAsyncThunk } from '@reduxjs/toolkit';
import totalPointTeam from '~/apis/analytics';
import { IQueryGetTables } from '~/apis/table';
import { TOTAL_POINT_TEAM_GET } from '~/utils/actionType';

export const getTotalPointTeam = createAsyncThunk(
  TOTAL_POINT_TEAM_GET,
  async (payload: IQueryGetTables, { rejectWithValue }) => {
    try {
      const response = await totalPointTeam.getTeam(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
