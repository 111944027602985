import { urlApiAnalytic } from '~/utils/constants';
import axiosClient from './axiosClient';

export interface IQueryParamGetTotalPointPlayer {
  tournamentId: string;
  teamId?: string | null | undefined;
  groupName?: string;
  page?: number | null | undefined;
  limit?: number | null | undefined;
}

const totalPointPlayer = {
  getTotalPointPlayer(params: IQueryParamGetTotalPointPlayer) {
    const { tournamentId, teamId, page, limit, groupName } = params;
    let url = `${urlApiAnalytic.getTotalPointPlayer}=${tournamentId}`;

    if (teamId) {
      url += `&teamId=${teamId}`;
    }
    if (groupName) {
      url += `&groupName=${groupName}`;
    }
    if (page && limit) {
      url += `&page=${page}&limit=${limit}`;
    }

    return axiosClient.get(url);
  },
};

export default totalPointPlayer;
