import { urlApiTournament } from '~/utils/constants';
import axiosClient from './axiosClient';

const tournamentApi = {
  getTournament() {
    const url = `${urlApiTournament}`;
    return axiosClient.get(url);
  },
};

export default tournamentApi;
