import axios from 'axios';
import { baseURL, env } from '~/utils/constants';
import { StorageConstants } from '~/utils/enum';
import refreshTokenApi from './refreshToken';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

if (env === 'test') {
  headers['ngrok-skip-browser-warning'] = '*';
}

const axiosClient = axios.create({
  baseURL: baseURL,
  headers: headers,
});

let isRefreshing = false;
let refreshSubscribers = [];

const onRequestSuccess = (config) => {
  const token = localStorage.getItem(StorageConstants.ACCESS_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const onResponseError = async (error) => {
  const originalRequest = error.config;

  if (
    error.response.status === 401 &&
    !originalRequest._retry &&
    localStorage.getItem(StorageConstants.ACCESS_TOKEN)
  ) {
    if (!isRefreshing) {
      isRefreshing = true;

      try {
        const refreshResponse = await refreshTokenApi.getRefreshToken({
          refreshToken: localStorage.getItem(StorageConstants.REFRESH_TOKEN),
        });

        const newAccessToken = refreshResponse.data.data.accessToken;
        const newRefreshToken = refreshResponse.data.data.refreshToken;

        localStorage.setItem(StorageConstants.ACCESS_TOKEN, newAccessToken);
        localStorage.setItem(StorageConstants.REFRESH_TOKEN, newRefreshToken);

        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        const response = await axios(originalRequest);

        // Mark the token refreshing process as finished
        isRefreshing = false;

        // Call any subscribers that were registered during the token refreshing process and pass the new token to them
        refreshSubscribers.forEach((subscriber) => subscriber(newAccessToken));
        refreshSubscribers = [];

        return response;
      } catch (error) {
        isRefreshing = false;
        // Notify all registered subscribers about the error occurred during token refreshing
        refreshSubscribers.forEach((reject) => reject(error));
        refreshSubscribers = [];

        return Promise.reject(error);
      }
    } else {
      const retrySubscribersPromise = new Promise((resolve, reject) => {
        // If a new token is received from the subscriber, update the header and retry the request
        refreshSubscribers.push((newAccessToken, error) => {
          if (newAccessToken) {
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            resolve(axios(originalRequest));
          } else {
            reject(error);
          }
        });
      });
      return retrySubscribersPromise;
    }
  }
  return Promise.reject(error);
};

axiosClient.interceptors.request.use(onRequestSuccess, (error) => Promise.reject(error));
axiosClient.interceptors.response.use((response) => response, onResponseError);

export default axiosClient;
