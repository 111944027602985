export const language = {
  EN: 'en',
  IT: 'it',
};

export const baseURL = process.env.REACT_APP_BASE_URL;
export const env = process.env.REACT_APP_ENV;

export const urlApiRound = {
  import: 'rounds/import',
  get: 'rounds?tournamentId',
  getSelection: 'rounds/options?tournamentId',
};

export const urlApiTournament = '/tournament';

export const urlApiTeam = '/teams?tournamentId';

export const urlApiAuth = {
  login: 'auth/login',
  refreshToken: 'auth/refresh-token',
};
export const urlApiAnalytic = {
  getTotalPointTeam: 'analytics/total-point-teams?tournamentId',
  getTotalPointPlayer: 'analytics/total-point-players?tournamentId',
};

export const codeResponseApi = {
  loginSuccess: 'LOGIN_SUCCESS',
  errNetwork: 'ERR_NETWORK',
};

export const statusPlayer = {
  starting: 'STARTING',
  substitute: 'SUBSTITUTE',
};

export const columnKeyMatch = {
  position: 'POSITION',
  name: 'NAME',
  team: 'REAL_TEAM',
  original: 'ORIGINAL_SCORE',
  fantasy: 'FANTASY_SCORE',
};

export const typeDataTotalPointPlayers = {
  table: 'TABLE',
  chart: 'CHART',
};

export const styleTitleChart = {
  fontSize: 14,
  fontWeight: 'bold' as const,
  fontFamily: 'Segoe UI, sans-serif',
};
