import { Button, Modal, Upload, UploadFile, message as toast } from 'antd';
import classNames from 'classnames/bind';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingContext } from '~/contexts/LoadingContext';
import { useAppDispatch } from '~/redux/hooks';
import { importFileExcel } from '~/thunks/round/roundThunk';
import { GROUPS } from '~/utils/constants.common';
import { MAX_SIZE_IMPORT_FILE_EXCEL, TYPES_OF_EXCEL } from '~/utils/enum';
import DropdownGroup from '../dropdownGroup/dropdownGroup';
import styles from './ModalImport.module.scss';

const cx = classNames.bind(styles);

const ERROR_CODE: any = {
  1000: 'import_error_can_not_parse_tournament_name',
  1001: 'import_error_can_not_parse_day',
  1002: 'import_error_must_be_4_matches',
  1003: 'import_error_can_not_parse_score',
  1004: 'import_error_can_not_assign_team_for_group',
  1005: 'import_error_starting_team_must_be_11_players',
  1006: 'import_error_group_must_be_have_38_days',
};

const ModalImport = ({
  showModal,
  setShowModal,
  onImportSuccess,
}: {
  showModal: boolean;
  setShowModal: Function;
  onImportSuccess: Function;
}) => {
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const uploadRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);
  const [file, setFile] = useState<UploadFile>();
  const [canImport, setCanImport] = useState(false);
  const [groupSelected, setGroupSelected] = useState<string>(GROUPS[0].value);
  const dispatch = useAppDispatch();

  const handleFileUpload = (file: UploadFile) => {
    const isExcel = file.type ? TYPES_OF_EXCEL.includes(file.type) : false;

    if (!isExcel) {
      setCanImport(false);
      setFile(undefined);
      toast.error(t('message_error_invalid_file_import_excel', { name: file.name }));
      return false;
    }

    if (!file?.size || file?.size > MAX_SIZE_IMPORT_FILE_EXCEL) {
      setCanImport(false);
      setFile(undefined);
      toast.error(t('message_error_invalid_size_file_import_excel', { name: file.name }));
      return false;
    }

    setFile(file);
    setCanImport(true);
    return false;
  };

  const handleImportFile = () => {
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('file', file as any);
    formData.append('groupName', groupSelected);
    loadingContext?.show();
    dispatch(importFileExcel(formData))
      .unwrap()
      .then((res) => {
        const { tournamentId } = res?.data;
        onImportSuccess({ groupSelected, tournamentImported: tournamentId });
        loadingContext?.hide();
        setShowModal(false);
        toast.success(t('message_success_import_excel', { name: file.name }));
      })
      .catch((err) => {
        loadingContext?.hide();
        const { error } = err?.response?.data?.data;
        const keyError = ERROR_CODE[error[0].code];
        toast.error(t(keyError, { name: file.name }));
      });
  };

  const handleUploadClick = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  };

  const FooterModal = () => {
    return (
      <div className={cx('footer_modal_import_container')}>
        <Button onClick={handleUploadClick}>{t('modal_upload')}</Button>
        <Button type='primary' onClick={handleImportFile} disabled={!canImport}>
          {t('modal_import')}
        </Button>
      </div>
    );
  };

  //#region Handle Function
  const handleChangeGroup = (value: string) => {
    setGroupSelected(value);
  };
  //#endregion Handle Function

  return (
    <Modal
      title={t('import_title_modal')}
      maskClosable={false}
      open={showModal}
      width={400}
      onCancel={() => setShowModal(false)}
      centered
      footer={<FooterModal />}
    >
      <DropdownGroup hasAll={false} onChange={handleChangeGroup} defaultValue={groupSelected} />
      {!file && <div>{t('message_modal_import')}</div>}
      <Upload
        className={cx('upload')}
        showUploadList={{ showRemoveIcon: false }}
        listType='picture'
        maxCount={1}
        beforeUpload={handleFileUpload}
        accept={TYPES_OF_EXCEL.join(',')}
      >
        <Button ref={uploadRef} style={{ display: 'none' }}></Button>
      </Upload>
    </Modal>
  );
};

export default ModalImport;
