import { createSlice } from '@reduxjs/toolkit';
import { getTotalPointTeam } from './totalPointTeamThunk';

interface ITotalPointTeamState {
  analyticTotalPointTeams: Array<any> | null | undefined;
}

const initialState: ITotalPointTeamState = {
  analyticTotalPointTeams: [],
};

const totalPointTeamSlice = createSlice({
  name: 'totalPointTeam',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTotalPointTeam.fulfilled, (state, action) => {

      state.analyticTotalPointTeams = action.payload.data.totalPointTeams;
    });
  },
});

export const totalPointTeamActions = totalPointTeamSlice.actions;

export const selectListTeam = (state: any) => state.totalPointTeam.analyticTotalPointTeams;

const totalPointTeamReducer = totalPointTeamSlice.reducer;
export default totalPointTeamReducer;
